.LivelinkDataPanelSkeleton {
    &-imageContainer {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        margin-bottom: 20px;
    }

    &-image {
        max-width: 495px;
        margin: 8px 0px;
    }
}
