@use '../../sp' as common;

.PartsCatalogueRedirectPanel {
    padding: 1rem;

    &-content {
        text-align: center;
        line-height: 1.5rem;
    }

    h2, h3 {
        color: common.$colour-accent;
        font-weight: bold;
        margin: 1.5rem 0;
    }

    h2 {
        font-size: 2rem;
    }

    h3 {
        font-size: 1.75rem;
        margin-bottom: 1rem;
    }

    hr {
        margin: 1rem 0;
    }

    ol {
        display: inline-block;
        padding-left: 0;
        margin-top: 0.5rem;
    }
    
    li {
        text-align: left;
    }
}
