@use '../sp' as common;

.TermsCoverPage {
    width: 100%;
    max-width: 1000px;
    margin: 0px auto;

    &-content {
        @extend .LegalDocumentView;
    }

    &-header {
        padding: 20px;
        background: common.$well-background;
        border-bottom: common.$light-border;

        h3 {
            font-size: common.$large-font-size;
            font-weight: bold;
            margin: 0px;
        }
    }

    &-buttons {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 20px;
        background: common.$well-background;
        border-top: common.$light-border;
    }
}
