.LivelinkDataPanel {
    &-mapImage {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 20px;
        gap: 8px;

        img {
            margin-bottom: 8px;
        }

        figcaption {
            text-align: center;
            line-height: 1.4em;
        }
    }
}
