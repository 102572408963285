$colour-accent: #fcb026;
$colour-accent-active: #f4a824;
$colour-accent-hover: darken($colour-accent-active, 1);
$colour-accent-selected: $colour-accent-active;
$app-background: white;
$title-link-colour: black;
$title-link-colour-hover: lighten($title-link-colour, 30);
$link-colour: #3366bb;
$link-colour-hover: lighten($link-colour, 10);
$button-text-colour: #212529;
$body-font-family: 'Roboto', 'Segoe', 'Calibri', 'Arial', sans-serif;
$heading-font-family: 'Roboto', 'Segoe', 'Calibri', 'Arial', sans-serif;
$heading-text-colour: black;

@forward '~@cognitranlimited/itis-web/dist/styles' with (
    $colour-accent: $colour-accent,
    $app-background: $app-background,
    $body-background: $app-background,
    $body-font-family: $body-font-family,
    $Link-text-colour: $link-colour,
    $Link-text-colour-hover: $link-colour-hover,
    $Link-title-text-colour: $title-link-colour,
    $Link-title-text-colour-hover: $title-link-colour-hover,
    $heading-text-colour: $heading-text-colour,
    $heading-font-family: $heading-font-family,
    $Button-text-colour: $button-text-colour,
    $Button-text-colour-disabled: rgba(0, 0, 0, 0.26),
    $Button-background-disabled: rgba(0, 0, 0, 0.12),
    $Button-background: $colour-accent,
    $Button-text-colour-danger: white,
    $Button-text-colour-outlined: $button-text-colour,
    $Button-border-outlined: 1px solid $button-text-colour,
    $Button-text-colour-outlined-hover: lighten($button-text-colour, 20),
    $Button-border-colour-outlined-hover: lighten($button-text-colour, 20),
    $Button-text-colour-outlined-active: $button-text-colour,
    $Button-border-colour-outlined-active: $button-text-colour,
    $Sidebar-text-colour: $heading-text-colour,
    $Sidebar-background: $colour-accent,
    $SidebarSectionItem-background-hover: $colour-accent-hover,
    $SidebarSectionItem-background-active: $colour-accent-active,
    $SidebarSectionItem-background-selected: $colour-accent-selected,
    $SidebarSection-background: $colour-accent,
    $Sidebar-light-text-colour: $title-link-colour,
    $Panel-header-text-transform: uppercase,
    $Panel-header-font-weight: 600,
    $Panel-text-colour: black,
    $ContentHeader-title-font-weight: 400,
    $VehicleHistoryPopover-header-font-weight: 500,
    $LanguageSelectItem-selected-text-colour: $title-link-colour,
    $NewLookPanel-background: #fff5e4,
    $ContentHeader-title-text-transform: uppercase,
    $ContentHeader-title-link-colour: $title-link-colour,
    $ContentHeader-title-link-colour-hover: $title-link-colour,
    $ContentHeader-title-link-colour-active: $title-link-colour,
    $Header-icon-colour: $title-link-colour,
    $IconButton-icon-colour: $title-link-colour,
    $IconButton-icon-colour-disabled: lighten($title-link-colour, 80),
    $NoticeItem-summary-text-colour: #5a5951,
    $NoticeItem-title-font-weight: 600,
    $MessageTableRow-read-text-colour: #5a5951,
    $MessageTableRow-read-background: #fafafa,
    $notification-text-colour: white,
    $Dialogue-header-font-text-transform: uppercase,
    $Expander-title-link-colour: $title-link-colour,
    $Expander-title-link-colour-hover: $title-link-colour-hover,
    $Expander-title-link-colour-active: $title-link-colour,
    $VehicleTabs-text-colour-hover: $heading-text-colour,
    $VehicleTabs-text-colour-selected: $heading-text-colour,
    $VehicleTabs-font-weight: bold,
    $DocumentLabel-background-new: $colour-accent,
    $DocumentLabel-text-colour-new: black
);

@use '~@cognitranlimited/itis-web/dist/styles/campaigns';

@use '@fontsource/roboto/scss/mixins' as Roboto;
@include Roboto.fontFace();

/**
 * Temporary overrides (these should have a corresponding core case to avoid accumulating styling overrides)
**/

:not(.VehicleHistoryButtons) > {
    .Button,
    a.ButtonLink {
        &,
        &:hover {
            font-size: 14px;
            padding: 12px 26px;
            font-family: $heading-font-family;
            font-weight: 500;
            font-stretch: condensed;
        }
    }
}

.VinSearch-error {
    white-space: pre-line;
}

.SubscriptionsTableRow .TableActionCell .ButtonLink {
    &,
    &:hover {
        padding: 8px 12px;
    }
}

// Document Header Title
.DocumentScreen, .DocumentGroupScreen {
    & .ContentHeaderTitle h2 {
        text-transform: none;
    }
}

.VehiclePopupToggle .MuiFab-root:hover {
    box-shadow: 0px 3px 10px -1px rgba(0,0,0,0.5),
        0px 6px 10px 0px rgba(0,0,0,0.14),
        0px 1px 18px 0px rgba(0,0,0,0.12);
}

.VinControlPanel-buttons {
    flex-flow: wrap;
}

@media screen and (max-width: 480px) {
    .VinControlPanel-buttons {
        flex-direction: column;
    }
}

.MuiToggleButton-primary.Mui-selected {
    color: black !important;
}
